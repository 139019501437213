import React, { useState } from "react"
import CTA from "../components/cta"
import Layout from "../components/layout"
import { loadStripe } from "@stripe/stripe-js"
import GuaranteeBadge from "../components/guarantee-badge"

let stripePromise
const getStripe = () => {
  if (!stripePromise) {
    stripePromise = loadStripe(
      "pk_live_51JYY4cGlZNC0CUqJD1FCCX91Qp0Yh2rsTYxr3ID7aDJZq7ZMY07bgDIbnw4oHY5A7oUHhFNPhIzzZH6gBZvI13BB004Ysj2Jrz"
    )
  }
  return stripePromise
}

const InstagramList = () => {
  const faq = [
    {
      q: "How does it work?",
      a:
        "Once you've purchased a list you will get a link to a google data sheet as well as a download link in your e-mail that you can use to download the file. Note that it can take up to 24hours for the link to generate.",
    },
    {
      q: "Do you work with the influencers on the list?",
      a:
        "We don't have any relationship with the influencers on theinfluencerlist. They are chosen based on their content and metrics alone.",
    },
    {
      q: "Why do you only release a list every 3 months?",
      a:
        "By comparing the list in a 3 month interval we get a more consistent tracking of their growth forecast.",
    },
    {
      q: "Why do some columns have the word (SAMPLE) added?",
      a:
        "We have the sample tag to note that the data in that column isn't for the entire lifespan of the account, but for a specific timespan. This is to ensure that the data we show is as much an accurate representation of the influencers current data as possible. And not their historical data.",
    },
    {
      q: "Why does the latest cost more than the previous version?",
      a:
        "In addition to updating all the previous influencers we also add ~20% more influencers each new version.",
    },
    {
      q: "How do i contact influencers when I'm done searching?",
      a:
        "We recommend you reach out to them personally on DM's on the respective platform introducing yourself, to give it a personal touch.",
    },
    {
      q: "I want a refund",
      a: "Contact us at hey@theinfluencerlist.co",
    },
    {
      q: "I got scammed!",
      a:
        "We are sorry to hear that. While we don't have a preexisting releationship with the influencers in our list fraudalent behaviour is not something we tolerate on our list. We can't do anything about your situation but we can stop it from happening to someone else. Please contact us at email@mail.com with details of who it was.",
    },
    {
      q: "I don't want my profile listed on theinfluencerlist",
      a:
        "No worries! Contact us with your profile details and we will remove you.",
    },
    {
      q: "I have a question that isn't answered here",
      a:
        "We are happy to answer all and any questions you have. Reach out to us on hey@theinfluencerlist.co",
    },
  ]

  const stripeCheckout = async (
    event,
    code = "price_1JkshKGlZNC0CUqJF0emwbTR"
  ) => {
    event.preventDefault()

    const stripe = await getStripe()

    const { error } = await stripe.redirectToCheckout({
      mode: "payment",
      lineItems: [{ price: "price_1JkshKGlZNC0CUqJF0emwbTR", quantity: 1 }],
      successUrl: `http://www.theinfluencerlist.co/success`,
      cancelUrl: `http://www.theinfluencerlist.co/cancel`,
    })

    if (error) {
      alert(`An error occured ${error.message}`)
    }
  }

  function gtag_report_conversion(url) {
    if (typeof window !== "undefined") {
      var callback = function () {
        if (typeof url != "undefined") {
          window.location = url
        }
      }
      window.gtag("event", "conversion", {
        send_to: "AW-614082583/QpTfCPX_3f0CEJfQ6KQC",
        event_callback: callback,
      })
      return false
    }
  }

  return (
    <Layout>
      <div className="map pb-8">
        <header className="text-center font-sans max-w-2xl px-4 sm:px-3 mx-auto pt-16 sm:pt-24 xl:pt-32 pb-16 sm:pb-24 md:pb-20 xl:pb-28">
          <h1 className="md:text-7xl sm:text-5.5xl font-extrabold tracking-tight mb-4">
            Start your Influencer Marketing Journey
          </h1>
          <p className="text-lg max-w-xl mx-auto mb-8">
            Save time and money. Unlock access to{" "}
            <span className="underline">over 1800</span> micro & nano
            influencers. <br />
          </p>
          <p className="text-green font-semibold font-sans">
            All the data you need to discover influencers aligned with your
            goals.
          </p>
          <div className="h-64 shadow-custom">
            <iframe
              className="w-full h-full shadow-custom"
              src="https://docs.google.com/spreadsheets/d/e/2PACX-1vQAFY3dmt6QHe_DONn4QIEwQzo7NVXphHtycm8-wqBdcNrX4tPSC-SopFbYRv4XN8yMqVk45Z61YfMm/pubhtml?gid=0&amp;single=true&amp;widget=true&amp;headers=false"
            ></iframe>
          </div>
        </header>
        <p
          id="instagram"
          className="text-center text-xl text-gray-700 font-thin"
        >
          Choose a list
        </p>
        <section className="bg-gray">
          <h2>What's Inside</h2>
          <ul>
            <li></li>
          </ul>
        </section>
        <section className="flex flex-col lg:flex-row m-h-16 m-8 lg:divide-x-2 lg:divide-gray-400">
          {/*Card*/}
          <div className="flex-1 text-center text-gray-700 opacity-75">
            <div className="px-4 mx-auto py-8 rounded-lg grid grid-cols-1 mt-8">
              {/**Item Header */}
              <div className="flex flex-col mb-10">
                <h2 className="mb-0">Spring</h2>
                <span className="text-sm text-gray-600 font-thin my-2">
                  (2021-Q2 Version)
                </span>
                <h3 className="flex item-center justify-center mx-auto  items-center text-5xl sans tracking-tight font-extrabold no-underline mx-3">
                  $129 <span className="ml-3 text-xl">USD</span>
                </h3>
              </div>
              <div className="h-2xl">
                <ul className="text-gray-600 mb-16">
                  <h3 className="uppercase text-lg text-left ">
                    What's included
                  </h3>
                  <li className="flex  items-center mx-auto">
                    <svg
                      aria-hidden="true"
                      width="12"
                      height="8"
                      fill="none"
                      className="text-teal-400 mr-3"
                    >
                      <path
                        d="M1.5 4.5l2.236 2.236a1 1 0 001.467-.056L10.5.5"
                        stroke="currentColor"
                        stroke-width="1.5"
                        stroke-linejoin="round"
                      ></path>
                    </svg>
                    <span>
                      More than{" "}
                      <span className="p-1 bg-grey font-mono"> 6000</span>{" "}
                      influencers
                    </span>
                  </li>
                  <li className="flex  items-center mx-auto">
                    <svg
                      aria-hidden="true"
                      width="12"
                      height="8"
                      fill="none"
                      className="text-teal-400 mr-3"
                    >
                      <path
                        d="M1.5 4.5l2.236 2.236a1 1 0 001.467-.056L10.5.5"
                        stroke="currentColor"
                        stroke-width="1.5"
                        stroke-linejoin="round"
                      ></path>
                    </svg>
                    <span>
                      Includes{" "}
                      <span
                        title="instagram, tiktok, youtube and twitter"
                        className="ml-1 underline italic"
                      >
                        4 platforms
                      </span>
                    </span>
                  </li>
                  <li className="flex  items-center mx-auto">
                    <svg
                      aria-hidden="true"
                      width="12"
                      height="8"
                      fill="none"
                      className="text-teal-400 mr-3"
                    >
                      <path
                        d="M1.5 4.5l2.236 2.236a1 1 0 001.467-.056L10.5.5"
                        stroke="currentColor"
                        stroke-width="1.5"
                        stroke-linejoin="round"
                      ></path>
                    </svg>
                    <span className="text-left">
                      12 metrics per influencer{" "}
                    </span>
                  </li>
                </ul>
              </div>
              <a className="block line-through bg-gray-500 text-white font-semibold font-sans lg:w-64 p-3 rounded-lg mx-auto mt-12 hover:shadow-xl cursor-not-allowed">
                Buy List
              </a>
            </div>
          </div>
          {/*Card 2 mid*/}
          <div className="flex-1 text-center relative">
            <h3 className="absolute w-full text-green uppercase tracking-wider text-sm font-normal">
              <span className="p-3 bg-light-green rounded-full">New</span>
            </h3>
            <div className="px-4 mx-auto py-8 rounded-lg grid grid-cols-1 ">
              {/**Item Header */}
              <div className="flex flex-col border-b-2 mb-10">
                <h2 className="mb-0">Instagram</h2>
                <span className="text-sm text-gray-600 font-thin my-2">
                  (Includes 2021-Q3 Data){" "}
                </span>
                <h3 className="flex item-center justify-center mx-auto text-5xl items-center sans tracking-tight text-blue font-extrabold no-underline mx-3">
                  <del className="text-gray-500 mr-3 text-xl">$89</del>$49{" "}
                  <span className="text-black ml-3 text-xl font-semibold">
                    USD
                  </span>
                </h3>
              </div>
              <div className="h-2xl">
                <ul className="text-gray-600 mb-16">
                  <h3 className="uppercase text-black text-lg text-left ">
                    What's included
                  </h3>
                  <li className="flex  items-center mx-auto">
                    <svg
                      aria-hidden="true"
                      width="12"
                      height="8"
                      fill="none"
                      className="text-teal-400 mr-3"
                    >
                      <path
                        d="M1.5 4.5l2.236 2.236a1 1 0 001.467-.056L10.5.5"
                        stroke="currentColor"
                        stroke-width="1.5"
                        stroke-linejoin="round"
                      ></path>
                    </svg>
                    <span>
                      <span className="p-1 bg-grey font-mono">1800</span>{" "}
                      Instagram influencers
                    </span>
                  </li>
                  <li className="flex  items-center mx-auto">
                    <svg
                      aria-hidden="true"
                      width="12"
                      height="8"
                      fill="none"
                      className="text-teal-400 mr-3"
                    >
                      <path
                        d="M1.5 4.5l2.236 2.236a1 1 0 001.467-.056L10.5.5"
                        stroke="currentColor"
                        stroke-width="1.5"
                        stroke-linejoin="round"
                      ></path>
                    </svg>
                    <span>Categorized in 10 unique niches.</span>
                  </li>
                  <li className="flex  items-center mx-auto">
                    <svg
                      aria-hidden="true"
                      width="12"
                      height="8"
                      fill="none"
                      className="text-teal-400 mr-3"
                    >
                      <path
                        d="M1.5 4.5l2.236 2.236a1 1 0 001.467-.056L10.5.5"
                        stroke="currentColor"
                        stroke-width="1.5"
                        stroke-linejoin="round"
                      ></path>
                    </svg>
                    <span className="text-left">
                      Often used #hashtags for each influencer
                    </span>
                  </li>
                  <li className="flex  items-center mx-auto">
                    <svg
                      aria-hidden="true"
                      width="12"
                      height="8"
                      fill="none"
                      className="text-teal-400 mr-3"
                    >
                      <path
                        d="M1.5 4.5l2.236 2.236a1 1 0 001.467-.056L10.5.5"
                        stroke="currentColor"
                        stroke-width="1.5"
                        stroke-linejoin="round"
                      ></path>
                    </svg>
                    <span className="text-left">
                      Between 1k and 1million followers per influencer
                    </span>
                  </li>
                  <li className="flex  items-center mx-auto">
                    <svg
                      aria-hidden="true"
                      width="12"
                      height="8"
                      fill="none"
                      className="text-teal-400 mr-3"
                    >
                      <path
                        d="M1.5 4.5l2.236 2.236a1 1 0 001.467-.056L10.5.5"
                        stroke="currentColor"
                        stroke-width="1.5"
                        stroke-linejoin="round"
                      ></path>
                    </svg>
                    <span className="text-left">
                      Engagement, follower and posts related metrics
                    </span>
                  </li>
                  <li className="flex  items-center mx-auto">
                    <svg
                      aria-hidden="true"
                      width="12"
                      height="8"
                      fill="none"
                      className="text-grey-400 mr-3"
                    >
                      <path
                        d="M1.5 4.5l2.236 2.236a1 1 0 001.467-.056L10.5.5"
                        stroke="currentColor"
                        stroke-width="1.5"
                        stroke-linejoin="round"
                      ></path>
                    </svg>
                    <span className="text-left">
                      Available as google data sheet or excel file
                    </span>
                  </li>
                </ul>
              </div>
              <button
                onClick={e => {
                  stripeCheckout(e)
                  gtag_report_conversion()
                }}
                className="block bg-dark-green text-white font-semibold font-sans lg:w-64 p-3 rounded-lg mx-auto mt-12 hover:shadow-xl cursor-pointer"
              >
                Buy List
              </button>
            </div>
          </div>
          {/*Card 3*/}
          <div className="flex-1 text-center text-gray-700 opacity-75">
            <div className="px-4 mx-auto py-8 rounded-lg grid grid-cols-1 mt-8">
              {/**Item Header */}
              <div className="flex flex-col mb-10">
                <h2 className="mb-0">Autumn</h2>
                <span className="text-sm text-gray-600 font-thin my-2">
                  (2021-Q3 Version)
                </span>
                <h3 className="flex item-center justify-center mx-auto  items-center text-5xl sans tracking-tight font-extrabold no-underline mx-3">
                  $189 <span className="ml-3 text-xl">USD</span>
                </h3>
              </div>
              <div className="h-2xl">
                <ul className="text-gray-600 mb-16">
                  <h3 className="uppercase text-lg text-left ">
                    What's included
                  </h3>
                  <li className="flex  items-center mx-auto">
                    <svg
                      aria-hidden="true"
                      width="12"
                      height="8"
                      fill="none"
                      className="text-teal-400 mr-3"
                    >
                      <path
                        d="M1.5 4.5l2.236 2.236a1 1 0 001.467-.056L10.5.5"
                        stroke="currentColor"
                        stroke-width="1.5"
                        stroke-linejoin="round"
                      ></path>
                    </svg>
                    All data from previous version
                  </li>
                  <li className="flex  items-center mx-auto">
                    <svg
                      aria-hidden="true"
                      width="12"
                      height="8"
                      fill="none"
                      className="text-teal-400 mr-3"
                    >
                      <path
                        d="M1.5 4.5l2.236 2.236a1 1 0 001.467-.056L10.5.5"
                        stroke="currentColor"
                        stroke-width="1.5"
                        stroke-linejoin="round"
                      ></path>
                    </svg>
                    Expanded on influencers from previous list
                  </li>
                  <li className="flex  items-center mx-auto">
                    <svg
                      aria-hidden="true"
                      width="12"
                      height="8"
                      fill="none"
                      className="text-teal-400 mr-3"
                    >
                      <path
                        d="M1.5 4.5l2.236 2.236a1 1 0 001.467-.056L10.5.5"
                        stroke="currentColor"
                        stroke-width="1.5"
                        stroke-linejoin="round"
                      ></path>
                    </svg>
                    <span>
                      <span className="text-green font-semibold">+ 23%</span>{" "}
                      more influencers
                    </span>
                  </li>
                  <li className="flex  items-center mx-auto">
                    <svg
                      aria-hidden="true"
                      width="12"
                      height="8"
                      fill="none"
                      className="text-teal-400 mr-3"
                    >
                      <path
                        d="M1.5 4.5l2.236 2.236a1 1 0 001.467-.056L10.5.5"
                        stroke="currentColor"
                        stroke-width="1.5"
                        stroke-linejoin="round"
                      ></path>
                    </svg>
                    <span className="text-left">
                      Updated data on previous influencers
                    </span>
                  </li>
                  <li className="flex  items-center">
                    <svg
                      aria-hidden="true"
                      width="12"
                      height="8"
                      fill="none"
                      className="text-teal-400 mr-3"
                    >
                      <path
                        d="M1.5 4.5l2.236 2.236a1 1 0 001.467-.056L10.5.5"
                        stroke="currentColor"
                        stroke-width="1.5"
                        stroke-linejoin="round"
                      ></path>
                    </svg>
                    <span className="text-left">
                      Includes growth tracking and trend statistics
                    </span>
                  </li>
                </ul>
              </div>
              <a className="block line-through bg-gray-500 text-white font-semibold font-sans lg:w-64 p-3 rounded-lg mx-auto mt-12 hover:shadow-xl cursor-not-allowed">
                Buy List
              </a>
            </div>
          </div>
        </section>
        <p className="w-2/3 mx-auto text-xs font-thin text-gray-600 mb-0">
          DISCLOSURE theinfluencerlist.co is only a influencer aggregator and
          has no relationship or contact with any of the influencers on the
          list. None are sponsored or given preferential treatment, all are
          chosen based on merit and metrics alone. As such any contact or
          arrangements made between you and the influencer falls outside the
          teams liability. By buying any list you agree to not hold the team
          liable for any losses or issues you may incur.
        </p>
      </div>

      <GuaranteeBadge />
      <section className="w-full h-36 bg-grey pt-12 pb-24">
        {/* FAQ */}
        <h2 className="text-center text-3xl uppercase track-wider">
          Frequently Asked Question
        </h2>
        <div>
          <ul className="">
            {faq.map(el => (
              <li className="mx-10 my-8 divide-y-2 divide-gray-400">
                <h3 className="text-xl m-1">{el.q}</h3>
                <p className="italic m-0 pt-2 mb-4 pl-4">{el.a}</p>
              </li>
            ))}
          </ul>
        </div>
      </section>
    </Layout>
  )
}

export default InstagramList
